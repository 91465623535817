import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationParameters, ClientAuthError, InteractionRequiredAuthError } from 'msal';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  //#region 'Angular Life Cycle'
  constructor(private authService: MsalService) {}
  //#endregion 'Angular Life Cycle'

  //#region 'Interceptor'
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        const userReq: AuthenticationParameters = {
          scopes: this.authService.getScopesForEndpoint(request.url)
        };
        if (err instanceof ClientAuthError) {
          if (!this.authService.getLoginInProgress()) {
            this.authService.loginRedirect();
            this.authService.handleRedirectCallback((authErr, res) => {
              if (authErr) {
                this.authService.logout();
              } else {
                return next.handle(request);
              }
            });

            return EMPTY;
          }
        }

        if (err instanceof InteractionRequiredAuthError) {
          if (!this.authService.getLoginInProgress()) {
            this.authService.acquireTokenRedirect(userReq);
            this.authService.handleRedirectCallback((authErr, res) => {
              if (authErr) {
                this.authService.logout();
                return EMPTY;
              } else {
                return next.handle(request);
              }
            });
          }
        }

        return throwError(err);
      })
    );
  }
  //#endregion 'Interceptor'
}
