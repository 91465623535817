import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@serv/shared/auth.service';
import { Observable, of } from 'rxjs';
import { pluck, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanActivate {
  //#region 'Angular Life Cycle'
  constructor(private authService: AuthService, public router: Router) {}
  //#endregion 'Angular Life Cycle'

  //#region 'Validations'
  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Stuck into pipes, due to the Observable nature of the authservice.login() - similar to the other guards
    const isGuest$ = this.authService.currentUser$.pipe(pluck('IsGuest')) as Observable<boolean>;

    return isGuest$.pipe(
      switchMap((isGuest: boolean) => {
        if (!isGuest) {
          // Everyone else other than guests can always pass
          return of(true);
        } else {
          // if guest, then check that the url is only '/analysis-reports/{id}'
          const regex = new RegExp('analysis-reports\\/\\d+', 'gm');

          if (regex.test(state.url)) {
            return of(true);
          } else {
            // A guest is trying to access something else, redirect to '/not-authorized'
            this.router.navigate(['/not-authorized']);
            return of(false);
          }
        }
      })
    );
  }
}
