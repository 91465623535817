import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  templateUrl: './logout.component.html',
  styleUrls: ['./../auth.styles.scss']
})
export class LogoutComponent implements OnInit {
  //#region 'Angular Life Cycle'
  constructor(private authSvc: MsalService) {}

  ngOnInit(): void {
    if (this.authSvc.getAccount()) {
      this.authSvc.logout(this.authSvc.getAccount().accountIdentifier);
    }
  }
  //#endregion 'Angular Life Cycle'
}
