import { MsalAngularConfiguration } from '@azure/msal-angular';
import { environment } from '@environment';
import { Configuration, CryptoUtils, Logger, LogLevel } from 'msal';

//#region 'Functions'
export function loggerCallback(logLevel: LogLevel, message: string): void {
  switch (logLevel) {
    case LogLevel.Error:
      // console.error(`[MSAL Error]: `, message);
      break;
    case LogLevel.Warning:
      // console.warn(`[MSAL Warning]: `, message);
      break;
    case LogLevel.Info:
      // console.log(`[MSAL Info]: `, message);
      break;
    case LogLevel.Verbose:
      // console.log(`[MSAL Verbose]: `, message);
      break;
  }
}

export function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId: environment.auth.clientId,
      authority: environment.auth.authority,
      postLogoutRedirectUri: environment.auth.postLogoutRedirectUri,
      redirectUri: environment.auth.redirectUri
    },
    system: {
      loadFrameTimeout: 30000,
      logger: new Logger(loggerCallback, {
        correlationId: CryptoUtils.createNewGuid(),
        level: LogLevel.Info,
        piiLoggingEnabled: false
      })
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    }
  };
}

export function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    popUp: false,
    protectedResourceMap: environment.protectedResourceMap as [string, string[]][] | Map<string, string[]>,
    consentScopes: ['user.read', 'openid', 'profile']
  };
}
//#endregion 'Functions'

//#region 'Enums'
export enum Role {
  BuAdmin = 0,
  SystemAdmin = 1,
  OrgAdmin = 2,
  Guest = 3,
  FacilityAdmin = 4
}
//#endregion 'Enums'
