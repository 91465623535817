import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@serv/shared/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./../auth.styles.scss']
})
export class LoginComponent implements OnInit {
  //#region 'Angular Life Cycle'
  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    if (this.authService.isAuthenticated) {
      this.router.navigate(['/']);
    } else {
      this.authService.loginRedirect();
    }
  }
  //#endregion 'Angular Life Cycle'
}
