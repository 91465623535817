import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DisclaimerNoticeComponent } from '@components/disclaimer-notice/disclaimer-notice.component';
import { AuthService } from '@serv/shared/auth.service';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, of } from 'rxjs';
import { catchError, map, pluck, switchMap, tap } from 'rxjs/operators';
import { StatusMessageApiService } from '../api/StatusMessage-Api.service';

@Injectable({
  providedIn: 'root'
})
export class DisclaimerGuard implements CanActivate {
  //#region 'Angular Life Cycle'
  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    public router: Router,
    private msgStats: StatusMessageApiService
  ) {}
  //#endregion 'Angular Life Cycle'

  //#region 'Validations'
  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isDisclaimerAccepter$ = this.authService.currentUser$.pipe(
      pluck('DisclaimerAccepted')
    ) as Observable<boolean>;

    this.msgStats.saveMsgsToStorages();

    return isDisclaimerAccepter$.pipe(
      switchMap((isAccepted) => {
        if (isAccepted) {
          return of(true);
        }

        const ref = this.dialogService.open(DisclaimerNoticeComponent, {
          header: 'NOTICE TO USERS:',
          modal: true,
          styleClass: 'dialog',
          closable: false
        });

        return ref.onClose.pipe(
          map((response) => !!response),
          tap((hasAccepted: boolean) => {
            if (!hasAccepted) {
              this.router.navigate(['/internal-error']);
            }
          }),
          catchError((err) => {
            this.router.navigate(['/internal-error']);
            return of(false);
          })
        );
      })
    );
  }
  //#endregion 'Validations'
}
