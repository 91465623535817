import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { MsalInterceptor, MsalModule, MsalService, MSAL_CONFIG, MSAL_CONFIG_ANGULAR } from '@azure/msal-angular';
import { ComponentsModule } from '@components/components.module';
import { GlobalHttpInterceptor } from '@interceptors/global-http.interceptor';
import { RetryInterceptor } from '@interceptors/retry.interceptor';
import { MSALAngularConfigFactory, MSALConfigFactory } from '@pages/auth/auth.constant';
import { ErrorModule } from '@pages/error/error.module';
import { MainModule } from '@pages/home/main/main.module';
import { AuthService } from '@serv/shared/auth.service';
import { GlobalErrorHandlerService } from '@serv/shared/global-error-handler.service';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { finalize } from 'rxjs/operators';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Angular
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    ProgressSpinnerModule,
    HttpClientModule,

    MainModule,
    ErrorModule,
    MsalModule,
    ToastModule,
    ComponentsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [AuthService, Router],
      useFactory: (authSvc: AuthService, router: Router) => {
        return (): Promise<any> => {
          return new Promise<void>((resolve) => {
            if (authSvc.isAuthenticated) {
              authSvc
                .loadUserInfo()
                .pipe(finalize(() => resolve()))
                .subscribe();
            } else {
              authSvc.login();
            }
          });
        };
      },
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory
    },
    {
      provide: MsalService,
      useExisting: AuthService
    },
    MessageService,
    DialogService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
