import { Component, OnInit } from '@angular/core';
import { ILoggedUser } from '@models/index';
import { AuthService } from '@serv/shared/index';
import { LocalStorageService } from '@serv/shared/local-storage.service';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BlobService } from 'src/services/api/Blob-Api.service';
import { LoadingIndicatorService, NotificationService } from '@serv/shared/index';

//#region 'MenuItem'
const SYSTEM_ADMIN_MENU_ITEMS: MenuItem[] = [
  {
    label: 'Azure Functions Logging',
    routerLink: '/azure-function-logging'
  },
  // {
  //   label: 'BU (JDE Sync)',
  //   routerLink: '/business-units'
  // },
  {
    label: 'Deleted Inspections',
    routerLink: '/deleted-inspections'
  },
  {
    label: 'Facilities Management',
    routerLink: '/facilities'
  },
  {
    label: 'Messages Management',
    routerLink: '/msg-management'
  },
  {
    label: 'System Admin',
    routerLink: '/system-admin'
  },
  {
    label: 'Users by Facility',
    routerLink: '/facility-user'
  },
  {
    label: 'Request Access',
    routerLink: '/request-access'
  }
];

const FACILITY_ADMIN_MENU_ITEMS: MenuItem[] = [
  {
    label: 'Users by Facility',
    routerLink: '/facility-user'
  }
];

//#endregion 'MenuItem'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [ConfirmationService]
})
export class HeaderComponent implements OnInit {
  //#region 'Variables'
  public brand: string = 'Heat Exchanger Analysis Tool';
  public currentUser$: Observable<ILoggedUser>;
  public showMenuButton: boolean = false;
  public menuItems: MenuItem[] = [];
  public profileMenuItems: MenuItem[] = [
    {
      label: 'Service Now',
      icon: 'pi pi-external-link',
      url: 'https://chevron.service-now.com/itserviceportal?id=sc_cat_item_guide&sys_id=9c08a21edb126450182e1dca48961981&sysparm_u_appid=5043320'
    },
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: () => this.authService.logout()
    }
  ];
  public disclaimer: string = '';
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    private authService: AuthService,
    private readonly _ls: LocalStorageService,
    private _srvConfirm: ConfirmationService,
    private _srvBlob: BlobService,
    private _srvNotify: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadCurrentUser();
    setTimeout(() => this.msgIfNotProduction(), 1000);
    // Message is set to show every 10 Minutes
    setInterval(() => this.msgIfNotProduction(), 600000);
    this.loadGeneralDisclaimer();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Load'
  private loadCurrentUser(): void {
    this.currentUser$ = this.authService.currentUser$.pipe(
      map((user: ILoggedUser) => ({
        ...user,
        FullName: `${user.FirstName} ${user.LastName}`
      })),
      tap(({ IsSystemAdmin, IsFacilityAdmin }: ILoggedUser) => {
        this.showMenuButton = IsSystemAdmin || IsFacilityAdmin;
        if (this.showMenuButton) {
          this.menuItems = IsSystemAdmin
            ? [...SYSTEM_ADMIN_MENU_ITEMS]
            : IsFacilityAdmin
            ? [...FACILITY_ADMIN_MENU_ITEMS]
            : [];
        }
      })
    );
  }

  private loadGeneralDisclaimer(): void {
    const DTO = this.sortByModified(this._ls.getItem('statusMessages'));
    try {
      this.disclaimer = DTO.find((x: any) => x.MessageType === 1 && !this.isExpired(x.Expires)).Message;
    } catch (e) {
      if (e instanceof TypeError) {
        console.warn('No general disclaimer');
      } else {
        console.error(e);
      }
    }
  }

  private msgIfNotProduction(): void {
    const ORIGIN = window.location.origin;
    let MSG = null;
    const DTO = this.sortByModified(this._ls.getItem('statusMessages'));
    try {
      MSG = DTO.find((x: any) => x.MessageType === 0 && !this.isExpired(x.Expires)).Message;
    } catch (e) {
      if (e instanceof TypeError) {
        console.warn('No environment redirect message');
      } else {
        console.error(e);
      }
    }
    if (MSG) {
      this._srvConfirm.confirm({
        header: 'You are not in production',
        message: MSG,
        acceptLabel: 'Go To Production',
        rejectLabel: 'Continue',
        accept: () => {
          window.location.href = 'https://heat.azure.chevron.com/';
        }
      });
    }
  }
  private isExpired(date: any): boolean {
    return new Date(date) < new Date();
  }
  private sortByModified(messages: any): any {
    return messages.sort((a, b) => (a.UpdatedDate > b.UpdatedDate ? -1 : a.UpdatedDate > b.UpdatedDate ? 1 : 0));
  }
  //#endregion 'Load'

  //#region 'download files'
  public downloadFile(fileName): void {
    this._srvBlob.processFileFromList(fileName).subscribe(
      (blob) => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        const fileType = this._srvBlob.fileType;
        a.href = objectUrl;
        a.download = `${fileName}.${fileType}`;
        a.click();
        URL.revokeObjectURL(objectUrl);
      },

      (err) => {
        this._srvNotify.showError('The requested file is not found');
      }
    );
  }
  //#endregion 'download files'
}
