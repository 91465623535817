import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Role } from '@pages/auth/auth.constant';
import { Observable } from 'rxjs';
import { AuthService } from './../shared/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanActivateChild, CanLoad {
  //#region 'Angular Life Cycle'
  constructor(private authSvc: AuthService, private router: Router) {}
  //#endregion 'Angular Life Cycle'

  //#region 'Validations'
  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const hasRoles = this.checkRoles(next.data.expectedRoles);
    if (!hasRoles) {
      this.router.navigate(['/']);
    }

    return hasRoles;
  }

  public canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const hasRoles = this.checkRoles(next.data.expectedRoles);
    if (!hasRoles) {
      this.router.navigate(['/']);
    }

    return hasRoles;
  }

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const hasRoles = this.checkRoles(route.data.expectedRoles);
    if (!hasRoles) {
      this.router.navigate(['/']);
    }

    return hasRoles;
  }

  private checkRoles(roles: Role[] = []): boolean {
    return roles.length > 0 && roles.some((role) => this.authSvc.hasRole(role));
  }
  //#endregion 'Validations'
}
