import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { DisclaimerGuard, GuestGuard, RoleGuard } from '@guards/index';
import { Role } from '@pages/auth/auth.constant';
import { LoginComponent } from '@pages/auth/login/login.component';
import { LogoutComponent } from '@pages/auth/logout/logout.component';
import { GenericErrorComponent } from '@pages/error/generic-error/generic-error.component';
import { NotAuthorizedComponent } from '@pages/error/not-authorized/not-authorized.component';
import { NotCompleteComponent } from '@pages/error/not-complete/not-complete.component';
import { NotFoundComponent } from '@pages/error/not-found/not-found.component';
import { MainComponent } from '@pages/home/main/main.component';
import { HttpClientModule } from '@angular/common/http';

const routes: Routes = [
  // *Lazy loaded Routes
  {
    path: '',
    component: MainComponent,
    canActivate: [MsalGuard, DisclaimerGuard, GuestGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('../pages/home/options/options.module').then((m) => m.OptionsModule)
      },
      {
        path: 'analysis-reports',
        loadChildren: () =>
          import('../pages/analysis-reports/analysis-reports.module').then((m) => m.AnalysisReportsModule)
      },
      {
        path: 'system-admin',
        loadChildren: () =>
          import('./../pages/admin/system-admin/system-admin.module').then((m) => m.SystemAdminPageModule),
        data: {
          expectedRoles: [Role.SystemAdmin]
        },
        canLoad: [RoleGuard]
      },
      {
        path: 'azure-function-logging',
        loadChildren: () =>
          import('./../pages/admin/azure-function-logging/azure-function-logging.module').then(
            (m) => m.AzureFunctionLoggingModule
          ),
        data: {
          expectedRoles: [Role.SystemAdmin]
        },
        canLoad: [RoleGuard]
      },
      {
        path: 'request-access',
        loadChildren: () =>
          import('./../pages/admin/request-access/request-access.module').then((m) => m.RequestAccessModule),
        data: {
          expectedRoles: [Role.SystemAdmin, Role.FacilityAdmin]
        },
        canLoad: [RoleGuard]
      },

      // Not Used
      {
        path: 'business-units',
        loadChildren: () =>
          import('../pages/business/business-units/business-units.module').then((m) => m.BusinessUnitsModule),
        data: {
          expectedRoles: [Role.SystemAdmin]
        },
        canLoad: [RoleGuard]
      },
      {
        path: 'facilities',
        loadChildren: () => import('../pages/business/facilities/facilities.module').then((m) => m.FacilitiesModule),
        data: {
          expectedRoles: [Role.SystemAdmin]
        },
        canLoad: [RoleGuard]
      },
      {
        path: 'facility-user',
        loadChildren: () =>
          import('./../pages/business/facility-user/facility-user.module').then((m) => m.FacilityUsersModule),
        data: {
          expectedRoles: [Role.SystemAdmin, Role.FacilityAdmin]
        },
        canLoad: [RoleGuard]
      },
      {
        path: 'deleted-inspections',
        loadChildren: () =>
          import('./../pages/admin/deleted-inspections/deleted-inspections.module').then(
            (m) => m.DeletedInspectionsPageModule
          ),
        data: {
          expectedRoles: [Role.SystemAdmin]
        },
        canLoad: [RoleGuard]
      },
      {
        path: 'msg-management',
        loadChildren: () =>
          import('./../pages/admin/msg-management/msg-management.module').then((m) => m.MsgManagementPageModule),
        data: {
          expectedRoles: [Role.SystemAdmin]
        },
        canLoad: [RoleGuard]
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
      }
    ]
  },

  // *Eager Loaded Routes
  {
    path: 'auth/login',
    component: LoginComponent
  },
  {
    path: 'auth/logout',
    component: LogoutComponent
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent
  },
  {
    path: 'not-complete',
    component: NotCompleteComponent
  },
  {
    path: 'internal-error',
    component: GenericErrorComponent
  },

  // Displays 404 "Not Found" error page for all unrecognized router.
  // Instead of redirecting, renders context to preserve original "incorrect" url
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy'
    }),

    HttpClientModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
