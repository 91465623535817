import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BroadcastService,
  MsalAngularConfiguration,
  MsalService,
  MSAL_CONFIG,
  MSAL_CONFIG_ANGULAR
} from '@azure/msal-angular';
import { ILoggedUser } from '@models/index';
import { Role } from '@pages/auth/auth.constant';
import { UserApiService } from '@serv/api';
import { Configuration } from 'msal';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends MsalService {
  //#region 'Variables'
  private readonly userSubject: BehaviorSubject<ILoggedUser>;
  readonly currentUser$: Observable<ILoggedUser>;
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    @Inject(MSAL_CONFIG) msalConfig: Configuration,
    @Inject(MSAL_CONFIG_ANGULAR) msalAngularConfig: MsalAngularConfiguration,
    router: Router,
    broadcastService: BroadcastService,
    private _apiUser: UserApiService
  ) {
    super(msalConfig, msalAngularConfig, router, broadcastService);
    this.userSubject = new BehaviorSubject(null);
    this.currentUser$ = this.userSubject.asObservable().pipe(shareReplay(1));
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Loads'
  public loadUserInfo(): Observable<ILoggedUser> {
    return this._apiUser.getUserCurrent().pipe(
      tap((data) => {
        this.userSubject.next(data);
      })
    );
  }
  //#endregion 'Loads'

  //#region 'Login'
  public login(): void {
    if (!super.getLoginInProgress()) {
      const loginRequest = { scopes: ['user.read', 'openid', 'profile'] };
      super.loginRedirect(loginRequest);
    }
  }

  public logout(): void {
    super.logout();
    this.userSubject.next(null);
  }
  //#endregion 'Login'

  //#region 'Gets'
  get currentUser(): ILoggedUser | null {
    return this.userSubject.value as ILoggedUser;
  }

  get fullName(): string {
    return this.currentUser && `${this.currentUser.FirstName} ${this.currentUser.LastName}`;
  }

  get isAuthenticated(): boolean {
    return !!super.getAccount();
  }

  get isAuthorized(): boolean {
    return !!this.currentUser && (this.currentUser.IsGuest || this.currentUser.ID > 0);
  }
  //#endregion 'Gets'

  //#region 'Validations'
  public hasRole(role: Role): boolean {
    if (this.isAuthorized && this.currentUser) {
      const { IsSystemAdmin, IsBUAdmin, IsOrgAdmin, IsGuest, IsFacilityAdmin } = this.currentUser;

      switch (role) {
        case Role.SystemAdmin:
          return IsSystemAdmin;
        case Role.BuAdmin:
          return IsBUAdmin;
        case Role.OrgAdmin:
          return IsOrgAdmin;
        case Role.Guest:
          return IsGuest;
        case Role.FacilityAdmin:
          return IsFacilityAdmin;
        default:
          return false;
      }
    } else {
      return false;
    }
  }
  //#endregion 'Validations'
}
